<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">成果宣传</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <div style="display: inline-block;text-align:center;width: 48%;height: 300px;margin-right: 5px;">
          <video 
            controls 
            muted 
            loop 
            width="100%"
            height="200px"
            >
            <source 
            src="https://app.dtspd.com/miniodata/dalianportal/01大连市多规合一“一张蓝图”（ 第二阶段工作成果）.mp4" 
            type="video/webm" 
            />
          </video>
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市多规合一“一张蓝图”</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 300px;margin-left: 5px;">
          <video 
            controls 
            muted 
            loop 
            width="100%"
            height="200px"
            >
            <source 
            src="https://app.dtspd.com/miniodata/dalianportal/07大连钻石海湾城市设计.mp4" 
            type="video/webm" 
            />
          </video>
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市钻石海湾城市设计</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 300px;margin-right: 5px;">
          <video 
            controls 
            muted 
            loop 
            width="100%"
            height="200px"
            >
            <source 
            src="https://app.dtspd.com/miniodata/dalianportal/02大连市实景三维.mp4" 
            type="video/webm" 
            />
          </video>
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市实景三维城市建模及应用</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 300px;margin-left: 5px;">
          <video 
            controls 
            muted 
            loop 
            width="100%"
            height="200px"
            >
            <source 
            src="https://app.dtspd.com/miniodata/dalianportal/04大连2049城市愿景规划.mp4" 
            type="video/webm" 
            />
          </video>
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连2049城市愿景规划</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 300px;margin-right: 5px;">
          <video 
            controls 
            muted 
            loop 
            width="100%"
            height="200px"
            >
            <source 
            src="https://app.dtspd.com/miniodata/dalianportal/06大连市总体规划.mp4" 
            type="video/webm" 
            />
          </video>
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">大连市总体城市设计</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 48%;height: 300px;margin-left: 5px;">
          <video 
            controls 
            muted 
            loop 
            width="100%"
            height="200px"
            >
            <source 
            src="https://app.dtspd.com/miniodata/dalianportal/03现状评估.mp4" 
            type="video/webm" 
            />
          </video>
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">现状评估</div> 
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>